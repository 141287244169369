import { makeStyles } from '@material-ui/styles'
import {
  ContentContainer,
  H1,
  H4,
  H5,
  MarkdownHandler,
  PageContainer,
  Text,
} from '@system'
import React from 'react'
import SbEditable from 'storyblok-react'
import GoogleStructuredData from './google-structured-data'

const useStyles = makeStyles((theme) => ({
  faqModuleHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  faqModuleIntro: {
    fontSize: '20px',
  },
  faqModuleOutro: {
    color: theme.palette.text.tertiary,
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.4',
    marginTop: '32px',
    maxWidth: '700px',
  },
  faqModuleSection: {
    alignItems: 'flexStart',
    display: 'flex',
    flexDirection: 'column',
  },
  faqModuleSectionTitle: {
    fontSize: '32px',
    margin: ' 40px 0 16px',
  },
  faqModuleQuestion: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    margin: '8px 0 0',
  },
}))

const FaqModule = ({ blok }) => {
  const classes = useStyles()
  const { introText, outroText, sections, title } = blok

  const renderFaqModuleSections = () =>
    sections?.map((section) => (
      <div className={classes.faqModuleSection} key={section._uid}>
        <H4 className={classes.faqModuleSectionTitle}>
          {section.sectionTitle}
        </H4>
        {section?.faqs?.map((faq) => (
          <React.Fragment key={faq._uid}>
            <Text component="p" className={classes.faqModuleQuestion}>
              {faq.question}
            </Text>
            <MarkdownHandler>{faq.answer}</MarkdownHandler>
          </React.Fragment>
        ))}
      </div>
    ))

  return (
    <SbEditable content={blok}>
      <GoogleStructuredData type="FAQPage">{sections}</GoogleStructuredData>
      <PageContainer>
        <ContentContainer>
          <div className={classes.faqModuleHeader}>
            <H1>{title}</H1>
            <MarkdownHandler className={classes.faqModuleIntro}>
              {introText}
            </MarkdownHandler>
          </div>
          <div>
            {renderFaqModuleSections()}
            <H5 className={classes.faqModuleOutro}>{outroText}</H5>
          </div>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default FaqModule
